<template>
  <a-card>
    <template #title>
      <a-form :model="form" layout="inline">
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.carrierName" placeholder="承运商" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.truckNo" placeholder="大车车牌" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-select v-model:value="form.settlementStatus" allowClear placeholder="结算状态">
            <a-select-option v-for="item in settlementStatus" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.dispatcher" placeholder="派车人" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.lineName" placeholder="路线" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-range-picker v-model:value="form.timeLimit" style="width:100%" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
            :showTime="false" :placeholder="['运输时间起', '运输时间止']" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-input v-model:value="form.transId" placeholder="运单编号" />
        </a-form-item>
        <a-form-item style="width: 13%">
          <a-space>
            <a-button type="primary" @click="search">查询</a-button>
            <a-button @click="resetSearch">重置</a-button>
            <a-button type="dashed" @click="exportData" :disabled="exportDisabled">导出Excel</a-button>
            <!-- <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
              <UpOutlined v-if="open" />
              <DownOutlined v-else />
            </a-button> -->
          </a-space>
        </a-form-item>
      </a-form>
    </template>
    <a-table :dataSource="dataSource" :rowKey="record => record.id" :loading="loading" :pagination="pagination"
      :scroll="{ x: true }" :style="{ marginTop: '16px' }" bordered size="small"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column :width="150" title="起运时间" data-index="departureTime" fixed="left" />
      <a-table-column :width="200" title="运单编号" data-index="transId" fixed="left" />
      <a-table-column :width="300" title="承运商" data-index="carrierName" fixed="left" />
      <a-table-column :width="200" title="线路" data-index="lineName" fixed="left" />
      <a-table-column :width="200" title="派车人" data-index="dispatcher" />
      <a-table-column :width="200" title="类型" data-index="truckType.label" />
      <a-table-column :width="100" title="车牌号/司机" data-index="transName" />
      <a-table-column :width="100" title="司机" data-index="driverName" />
      <a-table-column :width="150" title="到达时间" data-index="arrivalTime" />
      <a-table-column :width="200" title="总可结算金额" data-index="settableTotalAmt" />
      <a-table-column :width="200" title="总已结算金额" data-index="settledAmt" />
      <a-table-column :width="200" title="油卡已结算金额" data-index="settledOilAmt" />
      <a-table-column :width="200" title="转账已结算金额" data-index="settledCardAmt" />
      <a-table-column :width="100" title="结算状态" data-index="settlementStatus.label" />
    </a-table>
  </a-card>
</template>
<script>
import { useStore } from 'vuex'
// import { list as orgList } from '@/api/system/organzition'
import { transReportPage, transReportExport } from '@/api/trans/finance/bill'

import { reactive, ref, toRefs } from 'vue'
import { onMounted } from '@vue/runtime-core'

import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
export default {
  setup () {
    const store = useStore()
    const form = reactive({
      timeLimit: [],
      truckNo: '',
      dispatcher: '',
      carrierName: '',
      settlementStatus: null,
      transId: '',
      lineName: ''
    })

    const state = reactive({
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      },
      exportDisabled: false,
      open: false,
      loading: false,
      orgs: [],
      dataSource: [],
      detailsLength: 0,
      orgCheckedAll: true,
      settlementStatus: [
        { label: '未结算', value: 0 },
        { label: '部分结算', value: 1 },
        { label: '已结算', value: 2 }
      ]
    })

    const useForm = Form.useForm
    const { resetFields } = useForm(form, ref({}))

    const exportData = () => {
      state.exportDisabled = true
      transReportExport({
        ...form,
        startTime: form.timeLimit[0],
        endTime: form.timeLimit[1]
      }, '运单报表-财务').then(res => {
        setTimeout(function () {
          state.exportDisabled = false
        }, 10000)
      })
    }

    const loadData = () => {
      state.loading = true
      transReportPage({
        ...form,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        startTime: form.timeLimit[0],
        endTime: form.timeLimit[1]
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }

    onMounted(() => {
      state.orgs = store.state.app.orgTypeList.map(val => { return { ...val, checked: true } })
      // orgList({ orgType: 1 }).then(res => {
      //   if (res.code === 10000) {
      //     state.orgs = res.data.map(val => { return { ...val, checked: true } })
      //   }
      // })
      loadData()
    })

    const search = () => {
      state.pagination.current = 1
      loadData()
    }

    const resetSearch = () => {
      resetFields()
      loadData()
    }

    const orgChange = () => {
      form.officeOrgIds = state.orgs.filter(val => val.checked).map(val => val.id)
      state.orgCheckedAll = form.officeOrgIds.length === state.orgs.length
      search()
    }

    const orgChangeAll = checked => {
      state.orgs.forEach(val => { val.checked = checked })
      orgChange()
    }

    return {
      form,
      ...toRefs(state),
      search,
      resetSearch,
      orgChange,
      orgChangeAll,
      loadData,
      exportData
    }
  },
  components: {
    UpOutlined,
    DownOutlined
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
